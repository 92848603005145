import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  font-family: ${props => props.theme.fontSourceSansPro};
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 3rem 0 1.5rem;
  color: ${props => (props.fontColor || '#343434')};
  text-align: ${props => props.center ? 'center' : 'initial'};

  ${media.forPhoneOnly`
    text-align: ${props => props.alwaysCenter ? 'center' : 'left'};
  `}
`;

const Title = styled.h2`
  margin: 0 0 0.25rem;
  font-size: 1.5rem;
  font-weight: 700;

  ${media.forPhoneOnly`
    margin: 0 0 0.5rem;
  `}
`;

const SubTitle = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;

  ${media.forPhoneOnly`
    font-size: 0.875rem;
  `}
`;

const ActionButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const PageSectionHeaders = ({
  title,
  subtitle,
  center,
  alwaysCenter,
  actionButtonEl,
  fontColor
}) => (
  <Root
    center={center}
    alwaysCenter={alwaysCenter}
    fontColor={fontColor}
    key={title}
    data-testid="test-section-header"
  >
    <Title>{ title }</Title>
    <SubTitle>{ subtitle }</SubTitle>
    <ActionButton>{actionButtonEl}</ActionButton>
  </Root>
);

PageSectionHeaders.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  center: PropTypes.bool,
  alwaysCenter: PropTypes.bool,
  actionButtonEl: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  fontColor: PropTypes.string,
};

PageSectionHeaders.defaultProps = {
  title: '',
  subtitle: '',
  center: false,
  actionButtonEl: null,
  alwaysCenter: false,
  fontColor: '',
};

export default PageSectionHeaders;
